import Layout from "../../layouts"
import React, {useState, useContext, useEffect} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {Box, Card} from "@mui/material"

import Hider from "../../components/utils/Hider"
import CfpModify from "../../components/cfp/cfpmodify"
import {CfpDgBindMember} from "../../components/cfp/cfpdialogbindmember"
import {CfpBackTitle} from "../../components/cfp/cfpdetailstop"
import {CfpCmDetails} from "../../components/cfp/cfpdetails"
import {navigate} from "gatsby";
import {gUtils} from "../../components/utils/gutils";
import {wordConfig} from "../../config/configFont";

export default function FDetails({location = {}}) {
    const {state = {}} = location
    const {data} = state || {}
    const {gCfpfp, gCfp, gIotCfp, gCompanyEmployee} = useContext(GlobalStateContext)
    const [edit, setEdit] = useState(false)
    const [mdetails, setMdetails] = useState({})
    const [isBindMember, setIsBindMember] = useState(false)


    useEffect(() => {

        console.log("当前数据", data)
        if (!data || Object.keys(data).length === 0) {
            navigate("/");
        } else {
            setMdetails({
                [wordConfig.touchIndex]: gUtils.binaryToDecimal(data.fingerID),
                [wordConfig.touchDeviceName]: gCompanyEmployee.memberIDToName(data.memberID) ?? "-",
            })
        }

    }, [state, navigate, data])

    const handleEditClick = () => {
        setEdit(true)
    }
    useEffect(() => {
        gIotCfp.subIotDevices()
        return ()=>{
            gIotCfp.cancelSubscriptions();

        }
    }, []);
    const handleSureClick = (v) => {


        setEdit(false)

        data.name = v
        gCfpfp.updateFp("f", gCfp.touchId, data)


    }

    const handleClose = () => {
        setIsBindMember(false)
    }

    const handleSure = (mm) => {
        setIsBindMember(false)
        data.memberID = mm.subUUID
        gCfpfp.updateFp("f", gCfp.touchId, data, () => {
            setMdetails({
                [wordConfig.touchIndex]: gUtils.binaryToDecimal(data.fingerID),
                [wordConfig.touchDeviceName]: gCompanyEmployee.memberIDToName(data.memberID) ?? "-",
            })

        })

    }

    const handleBindMmClick = () => {
        setIsBindMember(true)
    }

    return (
        <Layout location={location}>
            <Card>
                <Hider show={edit}>
                    <CfpModify
                        type="f"
                        state={data}
                        leftClick={() => setEdit(false)}
                        sureClick={handleSureClick}
                    />

                    <Box>
                        <CfpBackTitle title={data?.name || ""} clickEdit={handleEditClick}/>
                        <CfpCmDetails mdetails={mdetails} bindMm={handleBindMmClick} isShowbindMm={true}/>
                    </Box>
                </Hider>
            </Card>
            <CfpDgBindMember
                mOpen={isBindMember}
                handleClose={handleClose}
                handleSure={handleSure}
            />
        </Layout>
    )
}
